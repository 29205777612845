import "./../App.css";

import { AiOutlineMenu, AiOutlinePlus } from "react-icons/ai";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import anime from "animejs";
import { navigationInCourse } from "../slices/appSlice";
import { useDispatch } from "react-redux";

const MenuDecider = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loc = useLocation();
  const handleIcon = () => {
    switch(loc.pathname + loc.hash) {
      case "/":
        setIcon("burger");
        break;
      case "/#menu":
        setIcon("plus");
        break;
      default:
        setIcon("close");
    }
  };
  const setIcon = (icon) => {
    switch (icon) {
      case "burger":
        anime({
          targets: ".menu-decider",
          rotateY: 180,
          rotateZ: 0,
          duration: 750,
          easing: "easeOutQuad",
        });
        anime({
          targets: ".close",
          opacity: 0,
          duration: 300,
          easing: "easeOutQuad",
        });
        anime({
          targets: ".burger",
          opacity: 1,
          delay: 350,
          duration: 300,
          easing: "easeOutQuad",
        });
        break;
      case "close":
        anime({
          targets: ".menu-decider",
          rotateY: 0,
          rotateZ: 135,
          duration: 750,
          easing: "easeOutQuad",
        });
        anime({
          targets: ".close",
          opacity: 1,
          delay: 350,
          duration: 300,
          easing: "easeOutQuad",
        });
        anime({
          targets: ".burger",
          opacity: 0,
          delay: 0,
          duration: 300,
          easing: "easeOutQuad",
        });
        break;
      case "plus":
        anime({
          targets: ".menu-decider",
          rotateY: 0,
          rotateZ: 0,
          duration: 750,
          easing: "easeOutQuad",
        });
        anime({
          targets: ".burger",
          opacity: 0,
          duration: 300,
          easing: "easeOutQuad",
        });
        anime({
          targets: ".close",
          opacity: 1,
          delay: 350,
          duration: 300,
          easing: "easeOutQuad",
        });
        break;
        default:
          console.error("not defined icon")
    }
  };
  useEffect(() => {
    anime.set(".menu-decider", { transformOrigin: "center", perspective: "360px", rotateY: 0, rotateZ: 0 });
  }, []);

  
  useEffect(() => {
    handleIcon();
  });

  const handleClick = () => {
    dispatch(navigationInCourse(true))
    switch (loc.pathname + loc.hash) {
      case "/":
        navigate("/#menu");
        break;
      case "/#menu":
        navigate("/");
        break;
      default:
        anime({
          targets: ".list",
          clip: "rect(0px 100vw 100vh 0px)",
          duration: 1000,
          easing: "easeOutQuad",
          complete: () => {
            navigate("/#menu");
          },
        });
    }
  };
  const onMouseLeave = () => {
    anime({ targets: ".menu-decider", boxShadow: "rgba(0, 0, 0, 0.6) 0px 0px 7px 2px" });
  };
  const onMouseEnter = () => {
    anime({ targets: ".menu-decider", boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 7px 2px" });
  };
  return (
    <div
      className="menu-decider"
   
      onMouseEnter={onMouseEnter}
      onClick={handleClick}
      onMouseLeave={onMouseLeave}>
      <AiOutlinePlus className="close" style={{ position: "absolute", opacity: 0,fontSize: 40, color: "#000" }} />
      <AiOutlineMenu
        className="burger"
        style={{ position: "absolute", opacity: 1, fontStyle: "40px", fontSize: 30, color: "#000" }}
      />
    </div>
  );
};
export default MenuDecider;
