import "./App.css";
import 'rsuite/dist/rsuite-no-reset.min.css';

import { Outlet, useNavigate } from "react-router-dom";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import Loader from "./components/Loader";
import Logo from "./components/objects/Logo";
import MainPage from "./components/MainPage";
import MenuDecider from "./components/MenuDecider";
import ThemeDecider from "./components/ThemeDecider";
import { outLetStyle } from "./Constants";
import {setNews} from "./slices/appSlice"
import { setTheme } from "./slices/appSlice";
import { useAnalytics } from 'use-analytics'

const layoutStyle = {
  userSelect: "none",
  overflow: "hidden",
  height: "100vh",
  top: 0,
  padding:5,
  left: 0,
  position: "fixed",
  width: "100vw",
};

const App = () => {
  const dispatch = useDispatch();

  const { theme, navigating } = useSelector((state) => state.reducers.app);
  const { page } = useAnalytics()
  const loaderRef = useRef();
  const getFeeds = async () => {
   let response = await loaderRef.current.load({ url: "https://cdn.advoxel.com/news/news.json", method: "GET" });
   dispatch(setNews(response.data))
  }
  useEffect(() => {
    page();
  }, [page])

  useEffect(() => {
    if (localStorage.getItem("theme")) {
      setTheme(localStorage.getItem("theme"));
    }
    getFeeds();
  }, []);
  return (
    <div className={ theme } style={ layoutStyle }>
      <div id="main_header">
        <Logo/>
      </div>
      { navigating ? <div id={ "dont_touch_layer" } style={ {cursor:"wait", position: "absolute", width: "100%", height: "100%", backgroundColor: "transparent", zIndex: 10000 } }></div> : null }
      <Loader ref={ loaderRef } />
      <MainPage />
      {/* <ThemeDecider /> */}
      <MenuDecider />
      <div style={outLetStyle}>
        <Outlet/>
      </div>
    </div>
  );
};

export default App;
