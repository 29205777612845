import "./../App.css";

import * as Yup from 'yup';

import { Button, Col, Container, FlexboxGrid, Form, Header, Heading, Input } from 'rsuite';
import { bodyPageStyle, headerStyle, headingStyle } from "../Constants";
import { forwardRef, useEffect, useRef } from 'react';
import { get, set } from 'lodash';

import axios from 'axios';
import { useFormik } from 'formik';

const Field = ({ error, ...rest }) => {
  return (
    <Form.Group>
      <Form.ControlLabel style={{color:"white"}}>{ get(rest, "label") }</Form.ControlLabel>
      <Input { ...rest } />
     {  get(rest, "required",false)===true? <Form.ErrorMessage show={ !!error } placement="topRight" style={{color:"black", backgroundColor:"red !important"}}>
        { error }
     
      </Form.ErrorMessage>:null}
     {  get(rest, "required",false)===true?    <Form.HelpText>Required</Form.HelpText>:null}
    </Form.Group>
  );
};

const Contact = () => {
  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email address').required('Required'),
    message: Yup.string().required('Required')
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: ''
    },
    validationSchema,
    onSubmit: values => {
      console.log(values);
      set(values, "reply",true)
      axios.post("http://localhost:8184/contact", values).then((response) => {
        console.log(response);
      }).catch((error) => {
        console.log(error)
      });
    }
  });

  useEffect(() => {
    console.log("mounted contact");
    return () => {
      console.log("unmounted contact");
    }
  }, []);

  return (
    <Container className="container" id="contact" style={ { backgroundColor: "black", flexDirection: "column", alignContent: "center", justifyContent: "center" } }>
      <Header  className="header"/>
      <Heading className="heading" style={{color:"white"}} level={3}>Contact</Heading>
      <FlexboxGrid justify="start" >
        <FlexboxGrid.Item  as={ Col } sm={20} md={ 17 }  lg={14} xl={10} colspan={ 24 }>
        </FlexboxGrid.Item>
      </FlexboxGrid>
      <FlexboxGrid>
        <FlexboxGrid.Item as={ Col } colspan={ 24 } sm={20} md={ 17 } lg={14} xl={10}>
          <Form onSubmit={ formik.handleSubmit }>
            <Field
              name="name"
              label="Name"
             
              value={ formik.values.name }
              error={ formik.errors.name }
              onChange={ value => formik.setFieldValue('name', value) }
            />
            <Field
              name="email"
              label="Email"
              required={true}
              value={ formik.values.email }
              error={ formik.errors.email }
              onChange={ value => formik.setFieldValue('email', value) }
            />
            <Field
              name="message"
              placeholder=""
              rows={ 5 }
              label="Message"
              as="textarea"
              required={true}
              value={ formik.values.message }
              error={ formik.errors.message }
              onChange={ value => formik.setFieldValue('message', value) }
            />
            <Button appearance="primary" type="submit">
              Submit
            </Button>
          </Form>
          </FlexboxGrid.Item>
      </FlexboxGrid>
    </Container>

  );
};
export default Contact;
