import "./../App.css";

import { AiOutlineMenu, AiOutlinePlus } from "react-icons/ai";
import {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";

import { Progress } from 'rsuite';
import anime from "animejs";
import axios from "axios";

const loaderStyle = {
  width: 80,
  left: "calc(50% - 40px)",
  top: "calc(50% - 40px)",
  height: 80,
  position: "absolute",
  opacity:1,
  zIndex: 100000000000,
  pointerEvents: "none",
  transition: "opacity 1s",
  backgroundColor: "rgba(255,0,0,0)",
};

const Loader = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const load = async (options) => {
   setLoading(true);
    return new Promise((resolve, reject) => {
      axios({
        ...options,
        onDownloadProgress: (progressEvent) => {
          console.log(progressEvent)
          let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          if (isNaN(percentCompleted)) {
            percentCompleted = 100;
          }
          setProgress(percentCompleted);
        },
      })
        .then((response) => {
          setLoading(false);
          resolve(response.data);
        })
        .catch((error) => {
         setLoading(false);
         setProgress(0);
          reject(error);
        });
    })
  }
  useImperativeHandle(ref, () => ({
    load,
  }));
  
  return (
    <div style={{...loaderStyle, opacity:loading?1:0}}>
      <Progress.Circle percent={progress} showInfo={true} strokeColor="#FFF" strokeWidth={4} trailWidth={2} trailColor="#000"/>
    </div>
  );
});
export default Loader;
