import { Center } from "@react-three/drei";
import { MathUtils } from "three";
import { useFrame } from "@react-three/fiber";
const PlaneStage = () => {
  // useFrame((state, delta, xrFrame) => {
  //   // This function runs at the native refresh rate inside of a shared render-loop
  // });
  return (
    <mesh rotation={[MathUtils.degToRad(-90),0,0]} position={[0,0,0]} receiveShadow>
          <planeGeometry args={[100, 100]} />
          <meshPhysicalMaterial color={"#000000"}  />
        </mesh>
  );
};
export default PlaneStage;
