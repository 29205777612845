import { Container, FlexboxGrid, Header, Heading } from 'rsuite';

import { setLogoColor } from '../slices/appSlice';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

const Privacy = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(setLogoColor("0%"));
    }, [])
    return (
        <Container className="container"  id="privacy">
            <Header className="header"  />
            <FlexboxGrid justify='start' align='top' style={ { backgroundColor: "white", paddingTop: 180 } }>
                <Heading className="heading" level={ 3 }>Privacy Policy</Heading>
                <p>
                    This Privacy Notice describes how Advoxel ad-server collects and processes your personal information through its services that are described in this Privacy Policy.
                </p>
                <p>
                    AdVoxel ad-server (“we” and “us”) firmly believes in the importance of online privacy. We do not collect information that enables us to determine your actual identity before, during or after your visit to this corporate website (advoxel.com), other than information that you voluntarily supply to us. We may, however, automatically receive and store certain other information, such as your IP address and browser type, when your device accesses our site, we do not use cookies on advoxel.com. In some areas of the world, information such as IP addresses, cookie identifiers and device identifiers may be considered to be personal information (sometimes referred to as personal data) that is protected under applicable laws. Where this is the case, we comply with applicable laws regarding our use and processing of personal information.
                </p>

                <h2>For What Purposes Does AdVoxel ad-server Use Your Personal Information?</h2>
                <p> AdVoxel ad-server do not use any personal information that you provide to us or that is collected by us when you interact with our AdVoxel ad-server Websites.
                </p>

                <h2>Data Collection through AdVoxel ad-server Services
                    What does AdVoxel ad-server do?</h2>
                <p>
                    There is no data collection through AdVoxel ad-server services, that use direct or indirect identifiers of any user. We do not collect any personal information about you see any advertisement served by AdVoxel ad-server.
                    We do not store any data regarding ad serving information, such as the time an ad was served, the IP address of the device that received the ad, the operating system of the device, the browser type, and other information about how the device interacts with the ad.
                    We do not save any personal information like a cookie, mobile advertising identifier, or other online advertising identifier.
                </p>

                <h2>How does AdVoxel ad-server deliver targeted advertising on behalf of our customers?</h2>
                <p> AdVoxel Ad Server’s technology does not provide any information to create targeted advertising. Other services that are external to AdVoxel may do so, but AdVoxel does not use any personal information to deliver targeted advertising.

                </p>

                <h2>Information Collected by Customers and Their Partners</h2>

                <p> Please note that AdVoxel ad-server customers sometimes include their own (or their partners’) ad-tags, pixels or similar technologies within advertisements that AdVoxel ad-server serves on behalf of those customers. AdVoxel ad-server does not control such collection or processing and we use reasonable efforts to require customers to treat such information in accordance with applicable laws and their own privacy policies.
                </p>
                <p>
                    In addition, if you visit a website or mobile application that uses AdVoxel ad-server services, or click through an ad to the website or online service of one of our customers, those customers may collect additional information about your visit beyond the information described in this privacy policy. They may also allow other companies (such as other advertising or web analytics services) to collect information through mechanisms such as cookies, pixels and similar technologies.
                </p>
                <p>
                    AdVoxel ad-server is not responsible for and cannot control the data collection and use practices of its customers or their partners. You should read the privacy notices of the websites you visit and the online services and interactive ads that you use to understand what information companies collect and how they use that information.
                </p>
                <p>
                    On occasion, at the request and on behalf of a customer, AdVoxel ad-server may collect personal information supplied voluntarily by consumers through their interaction with data collection fields in the body of advertisements or on websites (such as name, email, etc.). This personal information is provided directly to the customer on whose behalf the information was collected, or the customer’s service providers, and is processed by AdVoxel ad-server only to the extent and as long as is necessary to provide the advertising services for which the personal information was collected. AdVoxel ad-server is not responsible for its customers’ use of this voluntarily supplied data. AdVoxel ad-server does not distribute this personal information to any party other than those indicated within the advertisement or, at the customers’ direction, their service providers. AdVoxel ad-server never associates any of this personal information with any other data (such as ad serving information) gathered or used in the delivery of advertisements.
                </p>

                <h2>Links to Other Sites</h2>

                <p>  Our site and the advertisements we serve on behalf of customers may contain links to other sites whose information practices may be different from ours. In addition, there may be links to other sites provided within the body of advertisements on other websites. We do not have control over the information that is submitted to, or collected by, these third parties. You should consult the privacy policy of these other sites to learn how your privacy is protected.
                </p>
                <p>
                    We also subscribe to the policies of the IAB Europe Transparency and Consent Framework. For more information about these organizations, their policies and interest-based advertising, please visit their websites by clicking on the following links: IAB Europe Transparency and Consent Framework.
                </p>

                <h2>Data Security and Retention</h2>
                <p>
                    We do not store any data about our clients, have appropriate technical and organizational security measures in place to help protect the personal information that is submitted to us voluntarily through our website or collected through interactions with our services. However, no system can be completely secure, and we make no guarantees as to the safety or security of any information transmitted to or from this site or through our services. AdVoxel ad-server retains cookie information and information we receive from your device in log files for up to 13 months from the time it is stored, after which time the information is deleted.
                </p>

                <h2>Our Use of Advertising Technologies</h2>
                <p>
                    This section provides additional information about the various technologies that AdVoxel ad-server uses to manage AdVoxel ad-server and to deliver ads to you and services to our customers, including services that collect ad serving information when you interact with our customers’ ads or the sites and applications where their ads are provided.
                </p>

                <h2>Cookies</h2>
                <p> A cookie is a small text file that can be stored on your computer or device when you view an advertisement or website relating to one of our customers. The cookie does not contain your name, address, phone number, email address, or anything that enables us to determine your actual identity.
                    We do not use cookies to deliver our advertising services to our customers.
                </p>

                <h2>Mobile Advertising Identifiers</h2>
                <p> 
                    We do not use any mobile identifiers to deliver our advertising services to our customers.
                </p>
                <h2>Log files</h2>
                <p>
                    Like most websites and advertising services providers, we gather certain information when you visit or interact with AdVoxel ad-server and on our customers’ behalf when you encounter our customers’ ads and websites. This information is collected automatically and stored in log files. This information includes internet protocol (IP) addresses, browser type, internet service provider (ISP), referring/exit pages, operating system, date/time stamp, and clickstream data. This information, which does not allow us to determine your actual identity, is used for purposes such as administering the site and the services, analyzing trends, and measuring user engagement with content.
                </p>

                <h2>Pixels</h2>
                <p>On behalf of our customers, we may employ advertising pixels on websites and advertisements that interact with some of our services. Pixels help our customers better manage content and advertising on websites by informing as to what content is effective. Pixels are graphics embedded invisibly in web and advertising content and are about the size of the period at the end of this sentence. Pixels work with cookies and similar technologies to record the interactions of users with websites and advertisements.
                </p>

                <h2>Other Online Advertising Identifiers and Information</h2>
                <p>
                    AdVoxel ad-server may also use other online advertising identifiers to help better manage content and advertising on devices by informing (without using cookies) as to the content with which users interact and the effectiveness of such content. Such identifiers may be supplied to use by advertisers, publishers, or other advertising partners, and may work across multiple browsers and devices. Additionally, AdVoxel ad-server collects various information about a device, such as browser type or operating system. This information helps to determine the level of users’ engagement with our website or our customers’ advertisements and websites. The information gathered through the use of such identifiers is not associated with any information that would enable us to determine an individual’s actual identity.
                </p>

                <h2>Information Relating to Children</h2>
                <p>
                    Our products and services are not designed or intended for children (as defined in applicable data protection laws). We do not knowingly collect personal information from children on the AdVoxel ad-server Websites or through our services. Please advise us if you are aware of our having received personal information from a child, in which case we will take appropriate steps to delete it. For these purposes, “children” and “child” refer to ages defined by applicable law in the countries where we provide our services.
                </p>

                <h2>International Data Transfers and Data Privacy Framework</h2>
                <p>
                    All business data is stored in AdVoxel servers based in Europe (Amsterdam). We do not transfer any kind of data to any data storage of facility outside European Union.
                </p>

                <h2>IAB Europe Transparency and Consent Framework</h2>
                <p>
                    AdVoxel ad-server subscribes to the policies of the IAB Europe Transparency and Consent Framework. For more information, please visit https://iabeurope.eu/transparency-consent-framework/.
                </p>

                <h2> Legal Basis and Purposes for Processing</h2>
                <p>
                    If you are a user from the European Economic Area or the United Kingdom, our legal basis for collecting and using the personal information described above will depend on the personal information concerned and the specific context in which we collect it.
                </p>
                <h2>If You Have Questions About This Policy</h2>
                <p>

                    To address any questions, comments, or concerns related to our privacy policy, please contact our AdVoxel ad-server Support by email at support@voxelscope.com or by phone to +351 936 510 413.

                </p>
                <p>
                    { " Voxelscope LDA " + new Date().getFullYear() + ". All rights reserved." }
                </p>
            </FlexboxGrid>
        </Container>
    );
};
export default Privacy;
