import "./index.css";

import { RouterProvider, createBrowserRouter } from "react-router-dom";

import Analytics from "analytics";
import { AnalyticsProvider } from "use-analytics";
import App from "./App";
import ErrorPage from "./pages/ErrorPage";
import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom/client";
import googleAnalytics from "@analytics/google-analytics";
import { map } from "lodash";
import { routes } from "./Constants";
import {store} from "./store";

const analytics = Analytics({
  app: "advoxel",
  plugins: [
    googleAnalytics({
      measurementIds: ["G-4D9NJ81T2D"],
    }),
  ],
});

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <App/>,
      errorElement: <ErrorPage/>,
      children: map(routes, (route) => {
        return {
          path: route.path,
          element: route.element
        };
      }),
    }
  ],
  {
    basename: "/",
    future: {
      v7_normalizeFormMethod: true,
    },
  },
);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <AnalyticsProvider instance={analytics}>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </AnalyticsProvider>
  </React.StrictMode>,
);
