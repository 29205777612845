import "./../App.css";

import { useEffect, useRef, useState } from "react";

import anime from "animejs";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const Feed = (props) => {
  const { news } = useSelector((state) => state.reducers.app);
  const [currentNewsIndex, setCurrentNewsIndex] = useState(1);
  const feedRef = useRef();
  const feedContainer = useRef();
  const feedAnimationTimeline = useRef();
  const location = useLocation();
  const handleResize = () => {
    feedRef.current.style.left = window.innerWidth / 2 - feedRef.current.clientWidth / 2 + "px";
  }

  useEffect(() => {
    if (location.pathname === "/" && location.hash === "") {
      if (feedAnimationTimeline.current) {
        feedAnimationTimeline.current.play()
      } else {
        feedAnimationTimeline.current = anime.timeline({
          easing: "easeOutQuad",
        });
       news.forEach((element, index) => {
        console.log(document.getElementById(element.index).clientHeight);
        feedAnimationTimeline.current.add({
          targets: feedContainer.current,
          duration: 1000,
          translateY: -document.getElementById(element.index).offsetTop,
          begin:()=>{
            if(document.getElementById(element.index).previousElementSibling) {
              anime({targets:document.getElementById(element.index).previousElementSibling, opacity:0, duration:500, easing:"easeOutQuad"})
            }
           
            if(document.getElementById(element.index)) {
              anime({targets:document.getElementById(element.index), opacity:1, duration:500, easing:"easeOutQuad"})
              setCurrentNewsIndex(element.index)
            }
          },
          complete:()=>{
            //document.getElementById(element.index).offsetTop;
            if(document.getElementById(element.index)) {
             // feedRef.current.scrollTo({top:,behavior:"smooth"})
            }
          }
        }, "+=4000")
       });
      }
    } else {
      console.log(location, feedAnimationTimeline.current)
      if (feedAnimationTimeline.current) {
        feedAnimationTimeline.current.pause()
      }
    }
    anime({
      targets: feedRef.current,
      opacity: 1,
      duration: 1000,
      translateY: 0,
      easing: "easeOutQuad",
    });
  }, [location]);

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, []);
  return (
    <div id="feed" ref={ feedRef }>
      <div ref={ feedContainer }>
          <div  id={"welcome_message"} className="title-item"><div className="feed-title" style={{fontSize:30, fontWeight:"bold", textAlign:"center"}}>{ "Welcome to AdVoxel"}</div></div>
        { news.map((item, index) => {
          return <div key={ index } id={item.index} className="feed-item"><div onClick={ () => { window.open(item.link, "_blank") } } className="feed-title">{ item.title }</div><div className="feed-date">{ item.pubDate }</div></div>
        }) }
      </div>
    </div>
  );
};
export default Feed;
