import "./../App.css";

import { Button, Col, Container, FlexboxGrid, Header, Heading, List, Loader } from 'rsuite';
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";
import { bodyPageStyle, headerStyle, headingStyle } from "../Constants";
import { get, map, set } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import { MdRefresh } from "react-icons/md";
import Pattern1 from "../assets/pattern.jpg"
import Pattern2 from "../assets/pattern2.jpg"
import axios from 'axios';
import { setServices } from '../slices/appSlice';

const ServiceStatus = () => {
    const [loading, setLoading] = useState(false);
    const { servicesHealth, allServicesGood } = useSelector((state) => state.reducers.app);
    const dispatch = useDispatch();
    const getPings = () => {
        if (!allServicesGood) {
            setLoading(true)
            let promises = map(servicesHealth, (service) => {
                return new Promise((resolve, reject) => {
                    if (process.env.NODE_ENV === "development") {
                        axios.get(`http://localhost:${service.local}/ping`).then((response) => {
                            console.log(response)
                            resolve({ name: service.name, status: true })
                        }).catch((error) => {
                            resolve({ name: service.name, status: false })
                        })
                    } else {
                        axios.get(`https://${service.subdomain}.voxelscope.com/ping`).then((response) => {
                            resolve({ name: service.name, status: true })
                        }).catch((error) => {
                            resolve({ name: service.name, status: false })
                        })
                    }
                });
            })
            Promise.allSettled(promises).then((results) => {
                setLoading(false)
                dispatch(setServices(map(results, (result) => { return result.value })))
            })
        }

    };
    useEffect(() => {

        getPings()
        if (process.env.NODE_ENV === "development") {

        }
        console.log("mounted contact");
        return () => {
            console.log("unmounted contact");
        }
    }, []);

    return (
        <Container className="container"  id="contact" style={ { backgroundColor: "violet", flexDirection: "column", alignContent: "center", justifyContent: "center"
        } }>
            <Header className="header"  />
            <Heading className="heading"  level={ 3 }>Services Health</Heading>
            <FlexboxGrid justify="start" >
                <FlexboxGrid.Item as={ Col } sm={ 20 } md={ 17 } lg={ 14 } xl={ 10 } colspan={ 24 }>
                    <List style={ { boxShadow: "none" } } >
                        { servicesHealth.map((service) => {
                            return <List.Item style={ { backgroundColor: "transparent", boxShadow: "0 -1px 0 #000", display: "flex", justifyContent: "space-between" } } ><span style={ { textAlign: "left" } }>{ service.name }</span><span style={ { textAlign: "right" } }>{ loading ? <Loader speed={ "fast" } /> : <>{ service.status ? <FaCheckCircle color={ "olive" } /> : <FaExclamationCircle color={ "yellow" } /> }</> }</span></List.Item>
                        }) }
                    </List>
                    <Button startIcon={ <MdRefresh />
                    } loading={ loading } onClick={ () => {
                        if (!allServicesGood) {
                            getPings()
                        }
                    } } appearance="primary">Refresh</Button>
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </Container>

    );
};
export default ServiceStatus;
