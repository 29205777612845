import "./../App.css";

import React, { useEffect } from "react";
import { get, map } from "lodash";
import { navigationInCourse, setLogoColor } from "../slices/appSlice";
import { useLocation, useNavigate } from "react-router-dom";

import Feed from "../components/Feed";
import Frame from "./objects/Frame";
import anime from "animejs/lib/anime.es.js";
import { getList } from "./../Constants";
import { useDispatch } from "react-redux";

const introStyle = {
  width: "100vw",
  height: "100vh",
  overflow: "hidden",
  backgroundColor: "gray",
  clip: "rect(0px 0vw 100vh 0px)",
  position: "absolute",
  top: 0,
  left: 0,
};


const MainPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loc = useLocation();
  useEffect(() => {
    dispatch(setLogoColor("100%"))
    dispatch(navigationInCourse(true))
    switch (loc.pathname + loc.hash) {
      case "/":
        anime({
          targets: ".intro",
          clip: "rect(0px 100vw 100vh 0px)",
          duration: 1000,
          delay: 500,
          easing: "easeOutQuad",
          begin: () => {
            anime({
              targets: ".list", clip: "rect(0px 0vw 100vh 0px)", duration: 1000, easing: "easeOutQuad", complete: () => {
                setTimeout(() => {
                  dispatch(navigationInCourse(false))
                },250)
                
              }
            });

          },
        });
        break;
      case "/#menu":
        anime({
          targets: ".list",
          clip: "rect(0px 100vw 100vh 0px)",
          duration: 1000,
          delay: 500,
          easing: "easeOutQuad",
          begin: () => {
            anime({
              targets: ".intro", clip: "rect(0px 0vw 100vh 0px)", duration: 1000, easing: "easeOutQuad", complete: () => {
                setTimeout(() => {
                  dispatch(navigationInCourse(false))
                },250)
              }
            });
          },
        });
        break;
      default:
        anime({ targets: ".intro", clip: "rect(0px 0vw 100vh 0px)", duration: 1000, easing: "easeOutQuad" });
        anime({
          targets: ".list", clip: "rect(0px 0vw 100vh 0px)", duration: 1000, easing: "easeOutQuad", complete: () => {
            setTimeout(() => {
              dispatch(navigationInCourse(false))
            },250)
          }
        });
    }
  }, [loc]);

  return (
    <>
     
      <div className="intro" style={ introStyle }>
        <Frame />
      </div>
      <div className="list">
        <div style={ { left: "20vw", top: "25vh", position: "absolute" } }>
          { map(getList(), (value, key) => {
            return (
              <div
              className="menu-link"
                onClick={ () => {
                  navigate(value.key);
                  anime({
                    targets: ".list",
                    clip: "rect(0px 0vw 100vh 0px)",
                    duration: 1000,
                    easing: "easeInOutSine",
                  });
                  anime({
                    targets: ".intro",
                    clip: "rect(0px 0vw 100vh 0px)",
                    duration: 1000,
                    easing: "easeInOutSine",
                  });
                } }
                
                key={ key }>
                { get(value, "text") }
              </div>
            );
          }) }
        </div>
      </div>
    </>
  );
};
export default MainPage;
