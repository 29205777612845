import { filter, map } from "lodash";

import Contact from "./pages/Contact";
import ErrorPage from "./pages/ErrorPage";
import OurWork from "./pages/OurWork";
import Privacy from "./pages/Privacy";
import ServicesStatus from "./pages/ServicesStatus";

export const routes = [
    {
      visible:true,
      path: "work/:type?",
      text:"Work",
      key: "/work",
      element: <OurWork />,
    },
    {
      visible:true,
      path: "contact",
      text:"Contacts",
      key: "/contact",
      element: <Contact />,
    },
    {
      visible:true,
      path: "privacy",
      text:"Privacy",
      key: "/privacy",
      element: <Privacy />,
    },
    {
      visible:true,
      path: "status",
      text:"Services Status",
      key: "/status",
      element: <ServicesStatus />,
    }
]

export const outLetStyle = {position:"absolute", top:0, left:0, width:"100vw", height:"100vh",zIndex:-1}

export const getList = () => {
  return map(filter(routes,{visible:true}), (route) => {
      return {text:route.text, key:route.key};
  });
};

