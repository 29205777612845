import { AccumulativeShadows, Center, Float, Lightformer, OrbitControls, Plane, RandomizedLight, SpotLight } from "@react-three/drei";
import { ContactShadows, Environment, GizmoHelper, GizmoViewport, PerformanceMonitor } from "@react-three/drei";
import { useEffect, useRef, useState } from "react";

import { Canvas } from "@react-three/fiber";
import Cube from "./Cube";
import Feed from "../Feed"
import PlaneStage from "./PlaneStage";
import { degToRad } from "three/src/math/MathUtils.js";
import { useSelector } from "react-redux";

const Frame = () => {
  const orbitControlsRef = useRef();
  const { theme, news } = useSelector((state) => state.reducers.app);
  console.log("theme", theme);
  const [degraded, degrade] = useState(false);
  useEffect(() => {
    console.log("Frame mount")
  }, [])
  return (
    <>
    {news.length > 0 ? <Feed news={news} /> : null}
    <Canvas
      className="canvas"
      frameloop="demand"
      shadows={ { type: 'PCFSoftShadowMap' } }
      style={ { transition: "all 1s", filter: theme === "dark" ? "invert(0)" : "invert(100)" } }>
      { process.env.NODE_ENV === "development" ? <GizmoHelper
        minPolarAngle={ 0 }
        maxPolarAngle={ 0 }
        alignment="bottom-center" // widget alignment within scene
        margin={ [50, 50] } // widget margins (X, Y)
      >
        <GizmoViewport axisColors={ ["red", "green", "blue"] } labelColor="black" />
      </GizmoHelper> : null }
      <group position={ [0, 0, 0] }>
        <Center top>
          <Cube orbitControlsRef={ orbitControlsRef } />
        </Center>
        <Center bottom>
          <PlaneStage />
        </Center>
        <SpotLight shadow-mapSize-width={ 2048 * 2 } // Increase for better shadow resolution
          shadow-mapSize-height={ 2048 * 2 } // Increase for better shadow resolution
          shadow-radius={ 20 } visible={ true } distance={ 200 } power={ 1000 } intensity={ 500 } decay={ 2.3 } penumbra={ 2 } angle={ 21 } position={ [0, 3, 5] } castShadow />
      </group>
      { process.env.NODE_ENV === "development" ? <PerformanceMonitor onDecline={ () => degrade(true) } />:null}
      <OrbitControls
        ref={ orbitControlsRef }
        autoRotate={ false }
        autoRotateSpeed={ 4 }
        screenSpacePanning={ false }
        enablePan={ false }
        maxPolarAngle={ degToRad(40) }
        minPolarAngle={ degToRad(40) }
        minAzimuthAngle={ degToRad(161) }
        maxAzimuthAngle={ degToRad(161) }
        enableZoom={ true }
        enableDamping={ true }
        minDistance={ 5 }
      />

    </Canvas>
    </>
  );
};
export default Frame;
