import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  name: "",
  email: "@",
}

export const formSlice = createSlice({
  name: 'formSlice',
  initialState,
  reducers: {
    setName: (state, action) => {
      state.name = action.payload
    },
    setEmail: (state, action) => {
      state.email = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
const { actions, reducer } = formSlice
// Extract and export each action creator by name
export const { setName,setEmail} = actions
// Export the reducer, either as a default or named export
export default reducer