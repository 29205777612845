import "./../App.css";

import { Col, Container, FlexboxGrid, Form, Header, Heading } from 'rsuite';
import React, { useEffect } from "react";

import { setLogoColor } from "../slices/appSlice";
import { useDispatch } from "react-redux";

const OurWork = () => {
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(setLogoColor("0%"));
  },[]);
  return (
    <Container className="container"  id="ourwork" style={ { backgroundColor: "white", flexDirection: "column", alignContent: "center", justifyContent: "center", } }>
      <Header className="header"/>
      <Heading className="heading"  level={3}>Work in progress... </Heading>
      <FlexboxGrid justify="start" >
        <FlexboxGrid.Item  as={ Col } sm={20} md={ 17 }  lg={14} xl={10} colspan={ 24 }>
          We will load here our work in the next few days. <br></br>Thanks
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </Container>
  );
};
export default OurWork;
