import { createSlice } from '@reduxjs/toolkit'
import { find } from 'lodash';
const initialState = {
  theme:"dark",
  logoColor:"100%",
  loading:true,
  navigating:true,
  allServicesGood:false,
  news:[],
  progress:0,
  servicesHealth:[{ name: "Studio", status: null, local: 8184, subdomain: "api" }, { name: "Hosting", status: null, local: 8189, subdomain: "media" }, { name: "Analytics", status: null, local: 8185, subdomain: "metrics" }],
}
export const appSlice = createSlice({
  name: 'appSlice',
  initialState,
  reducers: {
    setTheme: (state,action) => {
      state.theme = action.payload;
    },
    setNews: (state,action) => {
      let index = 0
      action.payload.forEach((item)=>{
        index++
        item.index = "news_" + index
      });
      state.news = action.payload;
    },
    setLogoColor: (state,action) => {
      state.logoColor = action.payload;
    },
    navigationInCourse: (state,action) => {
      state.navigating = action.payload;
    },
    setServices:(state,action) => {
      let check = true;
      action.payload.forEach((service)=>{
          if(service.status === false) {
            check = false;
          }
          find(state.servicesHealth, { name: service.name }).status = service.status;
      });
      state.allServicesGood = check;
    },
   
  },
})
// Action creators are generated for each case reducer function
const { actions, reducer } = appSlice
// Extract and export each action creator by name
export const {  setNews,setTheme,navigationInCourse, setServices,setLogoColor } = actions
// Export the reducer, either as a default or named export
export default reducer;