import { Button, Col, Container, FlexboxGrid, Header, Heading, IconButton } from "rsuite";
import { useLocation, useNavigate, useRouteError } from "react-router-dom";

import PagePrevious from '@rsuite/icons/PagePrevious'
import logo from "../assets/logo_advoxel.svg"
import {setLogoColor}from "../slices/appSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";

export default function ErrorPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const error = useRouteError();
  useEffect(()=>{
    dispatch(setLogoColor("0%"));
    console.log(error,location)
    console.log("mounted error");
    return () => {
      console.log("unmounted error");
    }
  });
  return (
    <Container className="container" id="error_page" style={ { backgroundColor: "black",color:"white", flexDirection: "column", alignContent: "center", justifyContent: "center" } }>
    <Header  className="header"/>
    <Heading className="heading" level={3}>{error.status}</Heading>
    <FlexboxGrid justify="start" >
      <FlexboxGrid.Item  as={ Col } sm={20} md={ 17 }  lg={14} xl={10} colspan={ 24 }>
      {"Path: "} {location.pathname}
      </FlexboxGrid.Item>
     
    </FlexboxGrid>
    <FlexboxGrid justify="start" >
      <FlexboxGrid.Item  as={ Col } sm={20} md={ 17 }  lg={14} xl={10} colspan={ 24 }>
      {"Error message: "} {error.statusText}
      </FlexboxGrid.Item>
    </FlexboxGrid>
    <FlexboxGrid justify="start" >
      <FlexboxGrid.Item  as={ Col } sm={20} md={ 17 }  lg={14} xl={10} colspan={ 24 }>
         <IconButton style={{marginTop:30}}icon={<PagePrevious />} size="lg" circle onClick={()=>{
          navigate("/");
         }}></IconButton>
      </FlexboxGrid.Item>
    </FlexboxGrid>
    <div style={{filter:"invert(100)",position:"absolute", textAlign:"center",lineHeight:"70px", verticalAlign:"baseline", fontSize:10, bottom:20, left:0, width:"100%", height:30, backgroundImage:"url(" + logo +")",color:"black", backgroundSize:"contain", backgroundRepeat:"no-repeat", backgroundPosition:"center center"}}>{new Date().getFullYear()}</div>
  </Container>
  );
}