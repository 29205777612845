import logo from "../../assets/logo_advoxel.svg"
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
const Logo = () => {
  const navigate = useNavigate();
  const {logoColor} = useSelector((state) => state.reducers.app);
  // useFrame((state, delta, xrFrame) => {
  //   // This function runs at the native refresh rate inside of a shared render-loop
  // });
  return  <img className="logo" src={logo} onClick={()=>{navigate("/")}} alt="Logo"style={{filter: 'invert(' + logoColor + ')'}} />
};
export default Logo;
