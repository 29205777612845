import { Center, ContactShadows, Text } from "@react-three/drei";
import {useEffect, useState} from "react";

import anime from "animejs";
import { degToRad } from "three/src/math/MathUtils.js";
import { useFrame } from "@react-three/fiber";

const Cube = (props) => {
  const [orbitValues, setOrbitValues] = useState({distance:5, azimuth:degToRad(160)});
  const zoom = (targetValues) => {
    anime({targets: orbitValues, distance:targetValues.distance, azimuth:targetValues.azimuth, complete:()=>{}, update:(e)=>{
      setOrbitValues({distance:orbitValues.distance, azimuth:orbitValues.azimuth})
    },  duration: 5000, easing:"easeOutQuad"});
  };
  useFrame((state, delta) => {
      props.orbitControlsRef.current.minDistance = orbitValues.distance;
      props.orbitControlsRef.current.maxDistance = orbitValues.distance;;
      props.orbitControlsRef.current.minAzimuthAngle = orbitValues.azimuth;;
      props.orbitControlsRef.current.maxAzimuthAngle = orbitValues.azimuth;;
    return
  })
  useEffect(() => {
    zoom({distance:4,azimuth:degToRad(180)});
  }, []);

  // useFrame((state, delta, xrFrame) => {
  //   // This function runs at the native refresh rate inside of a shared render-loop
  // });
  return (
    <group rotation={[degToRad(0), degToRad(45),degToRad(0)]} position={[0,0,0]}>
      <Center>
        <mesh
          // onClick={(e) => {
          //  console.log("click")
          //  zoom({distance:10,azimuth:degToRad(90)});
          // }}
          castShadow={true}>
          <boxGeometry args={[1.5,1.5, 1.5]} />
          <meshPhongMaterial color={"#333"} flatShading={false} specular={0x0505050} shininess={20}/>
        </mesh>
      </Center>
    </group>
  );
};
export default Cube;
